<template>
  <div>
    <b-list-group-item
      class="property"
      @click="routeToEntity"
      @contextmenu.prevent="$refs.menu.open"
    >
      <h6 class="text-primary">
        {{ localProperty.name }}
      </h6>
      <div v-if="localProperty.description">
        <div v-html="localProperty.description" />
      </div>
      <b-row>
        <b-col>
          <div>
            Type:
            <b-badge class="mx-2" variant="secondary">
              {{ localProperty.type }}
            </b-badge>

          </div>
          <div>
            Unit of Measure:
            <b-badge class="mx-2" variant="secondary">
              {{ localProperty.unit }}
            </b-badge>
          </div>
        </b-col>
        <b-col>
          <div>
            Required:
            <feather-icon
              v-if="localProperty.required && (localProperty.required === true || localProperty.required === 'true')"
              icon="CheckIcon"
              class="animate-pulse text-success mr-50 font-small"
            />
            <feather-icon
              v-else
              icon="XIcon"
              class="animate-pulse text-danger mr-50 font-small"
            />
          </div>
          <div>
            Unique:
            <feather-icon
              v-if="localProperty.unique && (localProperty.unique === true || localProperty.unique === 'true')"
              icon="CheckIcon"
              class="animate-pulse text-success mr-50 font-small"
            />
            <feather-icon
              v-else
              icon="XIcon"
              class="animate-pulse text-danger mr-50 font-small"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="font-small-2">
            Range:
            <span v-if="(localProperty.min && localProperty.min !== '') || (localProperty.max && localProperty.max !== '')" class="font-small-2">[{{ localProperty.min }}..{{ localProperty.max }}]</span>
            <span v-else class="font-small-2 text-muted">No range constraints</span>
          </div>
        </b-col>
        <b-col>
          <div class="font-small-2">
            Default:
            <span v-if="localProperty.default && localProperty.default !== ''" class="font-small-2">{{ localProperty.default }}</span>
            <span v-else class="font-small-2 text-muted">No default defined</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="font-small-2">
            Validation / Format:
            <span v-if="localProperty.validation && localProperty.validation !== ''" class="font-small-2">{{ localProperty.validation }}</span>
            <span v-else class="font-small-2 text-muted">No validation/format defined</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-button-group class="mt-50" style="width: 30%">
          <b-button
            size="sm"
            variant="flat-info"
            @click="editProperty(localProperty)"
          >
            Edit
          </b-button>
          <b-button
            size="sm"
            variant="flat-danger"
            @click="deleteProperty(localProperty)"
          >
            Delete
          </b-button>
        </b-button-group>
      </b-row>
    </b-list-group-item>
    <EditProperty @edited="updateProperty" />
    <DeleteProperty />
  </div>
</template>

<script>
import EditProperty from '@/components/Domain/Modals/Properties/AttributeEdit.vue'
import DeleteProperty from '@/components/Domain/Modals/Properties/AttributeDelete.vue'

export default {
  name: 'ListGroupItemEntity',
  components: {
    EditProperty,
    DeleteProperty,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
    entity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localProperty: this.property,
      menuData: [
        { icon: 'ChevronRightIcon', text: 'Open in Ontology', method: () => this.routeToEntity() },
        { icon: 'ChevronsRightIcon', text: 'Open parent in Ontology', method: () => this.routeToParentEntity() },
      ],
    }
  },
  computed: {
    entityType() {
      return this.component.cpt_labels ? this.component.cpt_labels.filter(e => e !== 'Component') : []
    },
  },
  methods: {
    editProperty(val) {
      this.$store.dispatch('domainModel/selectEntity2', this.entity)
        .then(() => {
          this.$store.dispatch('domainModel/selectAttribute', val)
            .then(() => {
              this.$bvModal.show('edit-attribute-modal')
            })
        })
    },
    updateProperty(val) {
      console.log('Updating attribute: ', val)
      this.$emit('propertyChanged', val)
    },
    deleteProperty(val) {
      this.$store.dispatch('domainModel/selectAttribute', val)
        .then(() => {
          this.$bvModal.show('delete-attribute-modal')
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';

.btn-flat-primary:focus {
  background-color: unset;
}

.property {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.entity:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .entity {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .entity:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
