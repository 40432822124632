<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">
      <!--      <b-button @click="fetchInterfaces">-->
      <!--        Refresh-->
      <!--      </b-button>-->

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              debounce="750"
              placeholder="Search interfaces"
              @update="updateRouteQuery"
            />
          </b-input-group>
        </div>

        <b-button
          size="sm"
          variant="flat-primary"
          class="text-nowrap"
          title="Export interfaces to XLSX"
          @click="$bvModal.show('export-interfaces-modal')"
        >
          Export Interfaces
        </b-button>
      </div>

      <!-- Interfaces List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <draggable
          v-model="interfaces"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list"
        >
          <li
            v-for="interfaceItem in interfaces"
            :key="interfaceItem.id"
            class="todo-item"
            :class="{ 'completed': interfaceItem.isCompleted }"
            @click="handleRowClick(interfaceItem)"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <!--<b-form-checkbox-->
                  <!--  :checked="task.isCompleted"-->
                  <!--  @click.native.stop-->
                  <!--  @change="updateTaskIsCompleted(task)"-->
                  <!--/>-->
                  <span v-if="interfaceItem.display_id && interfaceItem.display_id !== ''" class="todo-title">
                    <span class="font-weight-bolder">{{ interfaceItem.display_id }}</span> - {{ interfaceItem.name }}
                  </span>
                  <span v-else class="todo-title">{{ interfaceItem.name }}</span>
                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge
                    pill
                    :variant="`light-${resolveTagVariant(interfaceItem.status)}`"
                    class="text-capitalize"
                  >
                    <span v-if="interfaceItem.status && interfaceItem.status != ''">{{ interfaceItem.status }}</span>
                    <span v-else>Proposed</span>
                  </b-badge>
                </div>
                <small class="text-nowrap text-muted mr-1">{{ interfaceItem.type }} - {{ interfaceItem.connection }}</small>
              </div>
            </div>

          </li>
        </draggable>
        <div
          class="no-results"
          :class="{'show': !interfaces.length}"
        >
          <div
            v-if="isInterfaceLoading"
            class="mt-5 d-flex justify-content-center"
          >
            <b-spinner style="width: 10rem; height: 10rem;" />
          </div>
          <h5 v-else>
            No Items Found
          </h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task Handler -->
    <interfaces-sidebar
      v-model="isInterfaceHandlerSidebarActive"
      :interface-prop="interfaceItem"
      :clear-interface-data="clearInterfaceData"
      @identification="updateInterfaceIdentification"
      @characteristics="updateInterfaceCharacteristics"
      @deleted="fetchInterfaces"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <interfaces-left-sidebar
        :is-interface-handler-sidebar-active.sync="isInterfaceHandlerSidebarActive"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <!-- Modals -->
    <AddInterfaceModal @added="fetchInterfaces" />
    <ExportInterfacesReport />
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend,
  BBadge,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import { formatDate, avatarText } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import AddInterfaceModal from '@/components/Interfaces/Modals/AddInterfaceModal.vue'
import ExportInterfacesReport from '@/components/Interfaces/Modals/ExportInterfacesReport.vue'
import InterfacesLeftSidebar from './InterfacesLeftSidebar.vue'
import interfacesStoreModule from './interfacesStoreModule'
import InterfacesSidebar from './InterfacesSidebar.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    draggable,
    VuePerfectScrollbar,

    // App SFC
    InterfacesLeftSidebar,
    InterfacesSidebar,

    // Modals
    AddInterfaceModal,
    ExportInterfacesReport,
  },
  setup() {
    const STORE_MODULE_NAME = 'app-interfaces'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, interfacesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    const { modelId } = router.currentRoute.params

    const interfaces = ref([])

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) sortBy.value = val
      else sortBy.value = val
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({ name: route.name, query: currentRouteQuery }).catch(() => {})
    }

    const blankInterface = {
      id: null,
      title: '',
      dueDate: new Date(),
      description: '',
      assignee: null,
      tags: [],
      isCompleted: false,
      isDeleted: false,
      isImportant: false,
    }
    const interfaceItem = ref(JSON.parse(JSON.stringify(blankInterface)))
    const clearInterfaceData = () => {
      interfaceItem.value = JSON.parse(JSON.stringify(blankInterface))
    }

    const updateInterfaceIdentification = interfaceData => {
      store.dispatch(`${STORE_MODULE_NAME}/updateInterfaceIdentification`, interfaceData)
        .then(() => {
          fetchInterfaces()
        })
    }
    const updateInterfaceCharacteristics = interfaceData => {
      store.dispatch(`${STORE_MODULE_NAME}/updateInterfaceCharacteristics`, interfaceData)
        .then(() => {
          fetchInterfaces()
        })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const isInterfaceHandlerSidebarActive = ref(false)

    const resolveTagVariant = tag => {
      if (tag === 'Proposed') return 'secondary'
      if (tag === 'In-Review') return 'secondary'
      if (tag === 'Confirmed') return 'info'
      if (tag === 'Assigned to Release') return 'info'
      if (tag === 'Developed' || tag === 'Tested') return 'primary'
      if (tag === 'Deployed') return 'success'
      return 'secondary '
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    // Search Functionality
    watch(routeParams, () => {
      fetchInterfaces()
    })
    const searchQuery = ref(routeQuery.value)
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const isInterfaceLoading = ref(false)
    const fetchInterfaces = () => {
      isInterfaceLoading.value = true
      interfaces.value = []
      store.dispatch(`${STORE_MODULE_NAME}/fetchInterfaces`, {
        model: modelId,
        // Search bar
        q: router.currentRoute.query.q,
        // One with the icons
        status: router.currentRoute.params.status,
        // One with coloured dot points
        type: router.currentRoute.params.type,
        // Second one with coloured dot points
        irl: router.currentRoute.params.irl,
        // Hamburger menu, top right of the screen
        sortBy: sortBy.value,
      })
        .then(response => {
          interfaces.value = response.data
        })
        .catch(e => { console.error(e) })
        .finally(() => {
          isInterfaceLoading.value = false
        })
    }
    fetchInterfaces()

    const handleRowClick = InterfaceData => {
      interfaceItem.value = InterfaceData
      isInterfaceHandlerSidebarActive.value = true
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      interfaceItem,
      interfaces,
      clearInterfaceData,
      searchQuery,
      fetchInterfaces,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,

      // UI
      resolveTagVariant,
      resolveAvatarVariant,
      isInterfaceHandlerSidebarActive,

      // Click Handler
      handleRowClick,

      // Filters
      formatDate,
      avatarText,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,

      // Interface
      updateInterfaceIdentification,
      updateInterfaceCharacteristics,
      isInterfaceLoading,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: visible;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
